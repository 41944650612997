<template>
	<section class="tf-contact tf-section">
        <div class="themesflat-container">
            <div class="row">
                <div class="col-12">
                    <div class="flat-form">
                        <h2 class="tf-title-heading style-2 mg-bt-12" style="margin-top: 150px;text-align: center;">
                            Apply For Listing
                        </h2>
                        <h5 class="sub-title ct style-1 pad-0-15">
                            You are a collection owner and willing to list it on <b>BlueDiamonds</b> ? Please fill this form !
                        </h5>
                        <div class="form-inner">
                            <form class="form-submit" id="contactform" action="javascript:void(0)" novalidate="novalidate" autocomplete="off" enctype="multipart/form-data" ref="formApply">
                                <p>If you provide this information, you will be able to edit your collection's details by yourself</p>
                                <input id="owner" name="owner" tabindex="1"  value="" type="text" placeholder="Your Wallet Address">
                                <p>The name of your collection</p>
                                <input id="collection" name="collection" tabindex="2" value="" aria-required="true" required type="text" placeholder="Collection name">
                                <p>The symbol of your NFTS on the blockchain (leave blank if you don't know)</p>
                                <input id="symbol" name="symbol" tabindex="3" value="" type="text" placeholder="Symbol">
                                <p>The supply, written only with figures (Ex: 10000)</p>
                                <input id="supply" name="supply" tabindex="4" value="" required type="number" placeholder="Supply">
                                <p>Total royalties, figures only (Ex: 8)</p>
                                <input id="royalties" name="royalties" tabindex="5" value="" required type="number" placeholder="Royalties">
                                <p>Mint date</p>
                                <input id="mint_date" name="mint_date" tabindex="6" value="" required type="date" placeholder="">
                                <p>The mint price (Ex: 1.5)</p>
                                <input id="mint_price" name="mint_price" tabindex="7" value="" required type="number" placeholder="Mint Price">
                                <p>A short description of your project</p>
                                <textarea id="description" name="description" tabindex="8" required placeholder="Description"></textarea>
                                <p>As a project owner, give people good reasons to buy NFTs from your collection</p>
                                <textarea name="why_you_should_buy" tabindex="9"  placeholder="Why you should buy ?"></textarea>
								
								<p>Twitter profile page link</p>
                                <input name="twitter" tabindex="10" value="" aria-required="true" required type="text" placeholder="Your twitter link">
								
								<p>Discord invite link</p>
                                <input name="discord" tabindex="11" value="" aria-required="true" required type="text" placeholder="Discord invite link">
								
								<p>Website</p>
                                <input name="website" tabindex="12" value="" aria-required="true" required type="text" placeholder="your website url">
								
                                <p>Collection Logo</p>
                                <input id="logo" name="logo" tabindex="13" value="" required type="file">
                                <p>Nfts preview image</p>
                                <input id="nft_preview" name="nft_preview" tabindex="14" value="" required type="file">
                                <button @click="send_form" class="submit">Send Application</button>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import $ from 'jquery';
import toastr from 'toastr';

import axios from 'axios';

let config_axios = {
    headers: {
        'Content-Type': 'application/json;',
    }
}

export default {
  name: 'ApplyForListing',
  
  data: function () {
    return {
        route_server: process.env.VUE_APP_ROOT_API,
        route_bo: process.env.VUE_APP_ROOT_BO,
    }
  },

  methods: {


    send_form: function(){
        
        var formData = new FormData(document.getElementById("contactform"));
        var $this = this;
        
        axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/apply_listing', formData).then(async function() {

            $this.$dtoast.pop({
                preset: "success",
                heading: 'Success',
                content:  'Thank you for you application ! We will come back to you soon',
              });
        });        
    },
  },

}
</script>